/* You can add global styles to this file, and also import other style files */


/* sticky footer */

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 320px;
  overscroll-behavior-y: none;
}

html,
body {
  height: 100%;
}

/* p tag styling for ml-auto and card-spacing is
   for mfe global preference opt-in correction */
p {
  &.ml-auto {
    margin: 1rem 0;
  }
  &.card-spacing {
    margin-top: 20px;
  }
}

/* Needed to hide default login header on new entry point page */
#inpage-login > section > div.-oneX-inpage-login-heading {
  visibility: hidden !important;
  height: 0;
}
.custom-row .row > * {
  width:auto !important;
  max-width:none !important;
  padding-right:none !important;
  padding-left:none !important;
  margin-top:none !important;
}
